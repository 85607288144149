.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





:root {
    /*
    --amplify-primary-color:  #ff9900; 
    --amplify-primary-contrast: var(�amplify-white);
    --amplify-primary-tint: #ffac31;
    --amplify-primary-shade: #e88b01;
    --amplify-secondary-color: #152939;
    --amplify-secondary-contrast: var(�amplify-white);
    --amplify-secondary-tint: #31465f;
    --amplify-secondary-shade: #1F2A37;
    --amplify-tertiary-color: #5d8aff;
    --amplify-tertiary-contrast: var(�amplify-white);
    --amplify-tertiary-tint: #7da1ff;
    --amplify-tertiary-shade: #537BE5;
    --amplify-grey: #828282;
    --amplify-light-grey: #c4c4c4;
    --amplify-white: #ffffff;
    --amplify-red: #dd3f5b;
*/
    --amplify-primary-color: #0275d8; /* changed */
    --amplify-primary-contrast: var(�amplify-white);
    --amplify-primary-tint: #5bc0de; /* changed */
    --amplify-primary-shade: #5bc0de; /* changed */;
    --amplify-secondary-color: #152939;
    --amplify-secondary-contrast: var(�amplify-white);
    --amplify-secondary-tint: #31465f;
    --amplify-secondary-shade: #1F2A37;
    --amplify-tertiary-color: #5d8aff;
    --amplify-tertiary-contrast: var(�amplify-white);
    --amplify-tertiary-tint: #7da1ff;
    --amplify-tertiary-shade: #537BE5;
    --amplify-grey: #828282;
    --amplify-light-grey: #c4c4c4;
    --amplify-white: #ffffff;
    --amplify-red: #dd3f5b;
}


/*
    Bootstrap colours

    Primary #0275d8 	rgb(2, 117, 216)    Blue
	Success #5cb85c 	rgb(92, 184, 92)    Green
	Info    #5bc0de 	rgb(91, 192, 222)   Light Blue
	Warning #f0ad4e 	rgb(240, 173, 78)   Amber
	Danger  #d9534f 	rgb(217, 83, 79)    Red
	Inverse #292b2c 	rgb(41, 43, 44)     Black ish
	Faded   #f7f7f7 	rgb(247, 247, 247)  White ish
    Dark 
*/




.menu_row {
  min-height: 8vh;
  margin: 0px;
  padding: 0px;
}

th {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #4CAF50;
  color: white;
  white-space: nowrap;

}



th, td {
  border: 1px solid #ddd;
  padding: 0px 8px;
  line-height: 1.8 !important;
}

td a  {
color: black !important;
}

th.BackupStatusTitleBlank, th.UptimeStatusTitleBlank, th.SummaryStatusTitleBlank, th.HostingStatusTitleBlank  {
  border: none;
}


th.HostingStatusTitle, th.HostingStatusTitleBlank, th.HostingStatusTotalTitle, th.HostingStatusBlank, th.UptimeStatusTitleBlank, th.BackupStatusTitleBlank, th.BackupStatusTitle, th.UptimeStatusTitle, th.SummaryStatusTitle, th.SummaryStatusTitleBlank, th.BackupSingleStatusTitle, th.UptimeSingleStatusTitle {
  background-color: grey;
}

td.HostingStatusTotalTitle {
  color: red;
  font-weight: 700;
}

td.HostingStatusTotal, td.HostingStatusTotalBlue  {
  color: red;
  font-weight: 700;
  text-align: right;
}

td.HostingStatusTotalBlue  {
  background-color: blue;
}

.BackupSingleStatusTable, .UptimeSingleStatusTable {
  font-size: 0.95em;
  font-family: Arial, Helivetica, sans-serif;
  border-collapse: collapse;
  margin-left:auto; 
  margin-right:auto;
}

.BackupStatusTable, .UptimeStatusTable, .SummaryStatusTable, .HostingStatusTable {
    font-size: 0.95em;
    font-family: Arial, Helivetica, sans-serif;
    border-collapse: collapse;
    margin-left:auto; 
    margin-right:auto;

}





tr:nth-child(even).BackupStatusRow, tr:nth-child(even).UptimeStatusRow, tr:nth-child(even).HostingStatusRow, tr:nth-child(even).SummaryStatusRow    {
    background-color: #f2f2f2;
}

tr:nth-child(odd).BackupStatusRow, tr:nth-child(odd).UptimeStatusRow, tr:nth-child(odd).HostingStatusRow, tr:nth-child(odd).SummaryStatusRow   {
  background-color: #f2f2f2;
}


td.number, td.Blue, td.Green, td.Amber, td.Red {
    text-align: right;
}

    td.Black {
      background-color: black;
      color: white;
    }

    td.Red,
    td.notziplmDays,
    td.ziplmDays,
    td.notzipfmDays,
    td.zipfmDays,
    td.fmDays {
        background-color: red;
        color: white;
    }

    td.Blue,
    td.ziplmDays.day0, 
    td.notziplmDays.day0,
    td.notzipfmDays.day0,
    td.ziplmDays.day0 {
        background-color: blue;
        color: white;
    }

    /* Backup Status Table */ 

    td.Green,
    td.notziplmDays.day1,
    td.ziplmDays.day1,
    td.ziplmDays.day2,
    td.ziplmDays.day3,
    td.ziplmDays.day4,
    td.ziplmDays.day5,
    td.ziplmDays.day6,
    td.ziplmDays.day7,
    td.zipfmDays.day0,
    td.zipfmDays.day1,
    td.zipfmDays.day2,
    td.zipfmDays.day3,
    td.zipfmDays.day4,
    td.zipfmDays.day5,
    td.zipfmDays.day6,
    td.zipfmDays.day7,
    td.zipfmDays.day8,
    td.zipfmDays.day9,
    td.zipfmDays.day10,
    td.zipfmDays.day11,
    td.zipfmDays.day12,
    td.zipfmDays.day13,
    td.zipfmDays.day14,
    td.notzipfmDays.day1,
    td.notzipfmDays.day2,
    td.notzipfmDays.day3,
    td.notzipfmDays.day4,
    td.notzipfmDays.day5,
    td.notzipfmDays.day6,
    td.notzipfmDays.day7,
    td.fmDays.day0,
    td.fmDays.day1,
    td.fmDays.day2,
    td.fmDays.day3,
    td.fmDays.day4,
    td.fmDays.day5,
    td.fmDays.day6,
    td.fmDays.day7,
    td.fmDays.day8,
    td.fmDays.day9,
    td.fmDays.day10,
    td.fmDays.day11,
    td.fmDays.day12,
    td.fmDays.day13 {
        background-color: green;
        color: white;
    }

    td.Amber,

    td.zipfmDays.day15,

    td.notzipfmDays.day8,
    td.notzipfmDays.day9,
    td.notzipfmDays.day10,
    td.notzipfmDays.day11,
    td.notzipfmDays.day12,

    td.notziplmDays.day2,
    td.notziplmDays.day3,
    td.notziplmDays.day4,
    td.notziplmDays.day5,
    td.notziplmDays.day6,
    td.notziplmDays.day7,
    td.fmDays.day14 {
        background-color: orange;
        color: white;
    }


    

    